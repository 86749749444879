/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */

import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  removeFile(event) {
    event.preventDefault();
    event.stopPropagation();
    if (confirm('Are you sure you want to delete this file?') === false) return;

    const { dataset } = event.currentTarget;
    const { entryId } = dataset;
    const attachmentId = dataset.fileId;
    this.callStimulusAction({
      cb: () => {
        this.stimulate('Store::Questionnaires::FileUpload#remove_file', entryId, attachmentId);
      },
    });
    event.currentTarget.closest('li.image_item').remove();
    if (document.querySelector('li.image_item') !== null) return;

    document.dispatchEvent(new Event('disable-form-submit-arrow'));
  }

  filesSelected(event) {
    const { files } = event.target;

    if (files.length > 0 && files.length <= 10) {
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];

        if (file.size <= 5 * 1024 * 1024) {
          if (/image\/.*/.test(file.type)) {
            document.dispatchEvent(new Event('enable-form-submit-arrow'));
          } else {
            this.showToast({
              text: 'One of your files has an invalid file type, please select files with a valid type.',
              type: 'error',
              duration: 5000,
            });
            event.target.value = '';
            document.dispatchEvent(new Event('disable-form-submit-arrow'));
            break;
          }
        } else {
          this.showToast({
            text: 'One of your files exceeds 5 MB in size, please choose files smaller than 5 MB.',
            type: 'error',
            duration: 5000,
          });
          event.target.value = '';
          document.dispatchEvent(new Event('disable-form-submit-arrow'));
          break;
        }
      }
    } else {
      this.showToast({
        text: 'Please select 10 or fewer files.',
        type: 'error',
        duration: 5000,
      });
      event.target.value = '';
      document.dispatchEvent(new Event('disable-form-submit-arrow'));
    }
  }
}
