import ApplicationController from '../../application_controller';
// https://yarnpkg.com/package/select2
// https://select2.org
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';

export default class extends ApplicationController {
  connect() {
    $(this.element).select2();

    // Set selected option values for Option Value Promotion Rule
    if (this.element.dataset.selectedIds != undefined && this.element.dataset.productRule == 'option_value') {
      const selectedIds = JSON.parse(this.element.dataset.selectedIds);
      $(this.element).val(selectedIds);
      $(this.element).trigger('change');
    }
  }
}
