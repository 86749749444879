import ApplicationController from '../../application_controller';
// https://yarnpkg.com/package/select2
// https://select2.org
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';

export default class extends ApplicationController {
  connect() {
    $(this.element).select2();
  }
}
