import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = ['allowedTags'];

  trixEl = document.querySelector('trix-editor');

  timerId = null;

  connect() {
    super.connect();
    this.checkTagsOnLoad();
    this.addClickEventOnEditorTags();
    document.addEventListener('trix-change', this.displayPreview.bind(this));
    document.addEventListener('trix-change', this.customizeFont.bind(this));
    this.trixEl.addEventListener(
      'keydown',
      this.disableEditorTyping.bind(this),
    );

    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Admin::Crm::EmailSignatureReflex#render_preview',
          this.trixEl.innerHTML,
        );
      },
    });
  }

  disconnect() {
    document.removeEventListener('trix-change', this.displayPreview.bind(this));
    document.removeEventListener('trix-change', this.customizeFont.bind(this));
    this.trixEl.removeEventListener(
      'keydown',
      this.disableEditorTyping.bind(this),
    );
    this.trixEl.innerHTML = '';
  }

  // Display preview on text editor change
  displayPreview() {
    const debouncePreview = () => {
      clearTimeout(this.timerId);

      this.timerId = setTimeout(
        () => {
          this.stimulate(
            'Admin::Crm::EmailSignatureReflex#render_preview',
            this.trixEl.innerHTML,
          );
        },
        this.timerId ? 1000 : 500,
      );
    };

    debouncePreview();
  }

  customizeFont(event) {
    if (event.detail && event.detail.attribute === 'fontSize') {
      const fontSize = event.detail.value;
      const selectedRange = this.trixEl.editor.getSelectedRange();

      if (selectedRange) {
        this.trixEl.editor.activateAttribute('fontSize', fontSize);
      }
    }

    if (event.detail && event.detail.attribute === 'fontFamily') {
      const fontFamily = event.detail.value;
      const selectedRange = this.trixEl.editor.getSelectedRange();

      if (selectedRange) {
        this.trixEl.editor.activateAttribute('fontFamily', fontFamily);
      }
    }

    this.addClickEventOnEditorTags();
  }

  disableEditorTyping(e) {
    const isCollapsed =
      this.trixEl.editor.selectionManager.selectionIsCollapsed();

    if (e.key !== ' ' && e.key !== 'Enter' && e.key !== 'Backspace') {
      e.preventDefault();
    }

    // Prevent deleting tags
    if (e.key === 'Backspace' || e.key === 'Delete') {
      const cursorPos = this.trixEl.editor.getPosition();

      const currentChar = this.trixEl.editor
        .getDocument()
        .toString()
        .charAt(cursorPos - 1);
      const previousChar = this.trixEl.editor
        .getDocument()
        .toString()
        .charAt(cursorPos - 2);

      if (
        previousChar.match(/[a-z]/i) ||
        currentChar.match(/[a-z]/i) ||
        !isCollapsed
      ) {
        e.preventDefault();
      }
    }

    if (e.key === 'Enter' || e.key === ' ') {
      if (!isCollapsed) {
        e.preventDefault();
      }
    }
  }

  // On page load ensure tags are only selectable if they are not already in the editor
  checkTagsOnLoad() {
    const allowedTags = JSON.parse(this.allowedTagsTarget.value);
    const editorLoadedContent = this.trixEl.querySelectorAll(
      'span, del, strong, em, u',
    );

    editorLoadedContent.forEach((editorEl) => {
      const tagName = editorEl.textContent.trim();
      const tagKeys = Object.keys(allowedTags);

      if (tagKeys.includes(tagName)) {
        const tagToRemove = document.querySelectorAll('.tag');

        tagToRemove.forEach((tag) => {
          if (
            tag.textContent.toLowerCase().replace('+', '').trim() ===
            tagName.toLowerCase().trim()
          ) {
            tag.remove();
          }
        });
      }
    });
  }

  // Inject any styles that are applied to the editor into the tag
  injectStyles() {
    const fontFamily = document.querySelector(
      '.trix-button-group--font-family',
    ).value;
    const fontSize = document.querySelector(
      '.trix-button-group--font-size',
    ).value;
    const isBold = this.trixEl.editor.attributeIsActive('bold');
    const isItalic = this.trixEl.editor.attributeIsActive('italic');
    const isUnderline = this.trixEl.editor.attributeIsActive('underline');
    return `font-family: ${fontFamily}; font-size: ${fontSize}; ${
      isBold ? 'font-weight: bold;' : ''
    } ${isItalic ? 'font-style: italic;' : ''}${
      isUnderline ? 'text-decoration: underline;' : ''
    }`;
  }

  onClickInsertTag(element) {
    const el = element.currentTarget;
    const tag = el.textContent.replace('+', '').trim();
    const style = this.injectStyles();
    const isStrike = this.trixEl.editor.attributeIsActive('strike');

    let tagHtml = `<span style="${style}">${tag}</span>&nbsp;`;

    if (isStrike) {
      tagHtml = `<span style="${style}"><del>${tag}</del></span>&nbsp;`;
    }

    this.trixEl.editor.insertHTML(tagHtml);

    el.remove();
  }

  addClickEventOnEditorTags() {
    document.querySelectorAll('trix-editor span').forEach((el) => {
      el.removeEventListener('dblclick', this.onDblClickRemoveTag.bind(this));
      el.addEventListener('dblclick', this.onDblClickRemoveTag.bind(this));
      el.addEventListener('click', this.onClickHighlightTag.bind(this));
    });
  }

  onClickHighlightTag(e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    const range = document.createRange();
    range.selectNodeContents(e.currentTarget);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  onDblClickRemoveTag(e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    e.currentTarget.remove();

    const allowedTags = JSON.parse(this.allowedTagsTarget.value);
    const tag = e.currentTarget.textContent.trim();
    const tagKeys = Object.keys(allowedTags);

    if (!tag.length) return;
    if (tagKeys.includes(tag)) {
      this.appendToTagsList(tag);
    }
  }

  appendToTagsList(tag) {
    // Get a reference to the tag container element
    const tagsContainer = document.querySelector('#tags-container');

    // Create a new span element
    const span = document.createElement('span');

    span.classList.add(
      'tag',
      'inline-flex',
      'items-center',
      'rounded-full',
      'bg-gray-50',
      'px-2',
      'py-1',
      'text-xs',
      'font-medium',
      'text-gray-600',
      'ring-1',
      'ring-inset',
      'cursor-pointer',
      'gap-x-0.5',
      'ring-gray-500/10',
      'hover:bg-gray-500/20',
      'border-[1px]',
    );

    span.setAttribute(
      'data-action',
      'click->admin--crm--email-signature#onClickInsertTag',
    );

    span.textContent = `+ ${tag}`;

    // Append the span element to the tag container
    tagsContainer.appendChild(span);
  }
}
