import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static inputContainerClass = '.input-container';

  connect() {
    super.connect();
    this.initializeListeners();
    this.triggerFocusOnAutofill();
  }

  getInputs() {
    return this.element.querySelectorAll('.store-input');
  }

  initializeListeners() {
    this.getInputs().forEach((input) => {
      const parent = input.closest(this.constructor.inputContainerClass);
      this.updateFilledClass(input);

      input.addEventListener('focus', () => parent.classList.add('typing'));
      input.addEventListener('focusout', () =>
        parent.classList.remove('typing'),
      );
      input.addEventListener('blur', () => this.updateFilledClass(input));
      input.addEventListener('keyup', () => this.updateFilledClass(input));
      input.addEventListener('keydown', () => this.updateFilledClass(input));
    });
  }

  updateFilledClass(input) {
    const parent = input.closest(this.constructor.inputContainerClass);
    parent.classList.toggle('filled', input.value.trim() !== '');
    parent.classList.remove('typing');
    this.filledInitialCheck();
  }

  triggerFocusOnAutofill() {
    window.addEventListener('load', () => {
      this.getInputs().forEach((input) => {
        const isAutofilled =
          window
            .getComputedStyle(input)
            .getPropertyValue('background-color') === 'rgb(232, 240, 254)';
        if (isAutofilled || input.value.trim() !== '') {
          input.dispatchEvent(new Event('focus'));
        }
      });
    });
  }

  filledInitialCheck() {
    const emailInput = document.querySelector('input#user_email');
    const fnameInput = document.querySelector('input#user_first_name');
    const lnameInput = document.querySelector('input#user_last_name');
    if (
      emailInput.value.trim() !== '' &&
      fnameInput.value.trim() !== '' &&
      lnameInput.value.trim() !== ''
    ) {
      const user_password = document.querySelector('input#user_password');
      user_password
        .closest(this.constructor.inputContainerClass)
        .classList.remove('!hidden');
    }
  }
}
