import ApplicationController from "./application_controller";
import Cleave from "cleave.js";
import "jquery-mask-plugin";

export default class extends ApplicationController {
  static targets = ["form", "text-field", "errorContainer"];
  static values = { roleType: String };

  connect() {
    super.connect();
    this.setFieldMasks();
  }

  inputChange(element) {
    if (element.target.value) {
      element.target.classList.remove("error");

      if (
        element.target.nextElementSibling &&
        element.target.nextElementSibling.classList.contains("text-error")
      ) {
        element.target.nextElementSibling.textContent = "";
      }
    }
  }

  submitError(event) {
    let [xhr] = event.detail;

    const { errors } = xhr;

    this.errorContainerTargets.forEach((errorContainer) => {
      const errorType = errorContainer.dataset.errorType;
      const errorMsg = this.extractError({ errors, type: errorType });

      errorContainer.innerText = errorMsg || "";

      this.setFieldMasks();
    });
  }

  submitForm(event, settings) {
    $(".phone_field").unmask();

    let isValid = this.validateForm(this.formTarget);

    // If our form is invalid, prevent default on the event
    // so that the form is not submitted
    if (!isValid) {
      event.preventDefault();
      this.setFieldMasks();
      return;
    }
  }

  validateForm() {
    let isValid = true;

    let requiredFieldSelectors =
      "textarea:required, input:required, select:required";

    let requiredFields = this.formTarget.querySelectorAll(
      requiredFieldSelectors
    );

    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus();

        field.classList.add("error");

        if (field.nextElementSibling) {
          field.nextElementSibling.textContent = "Can't be blank";
        }

        isValid = false;

        return false;
      }
    });

    // If we already know we're invalid, just return false
    if (!isValid) {
      return false;
    }

    // Search for any browser invalidated input fields and focus them
    let invalidFields = this.formTarget.querySelectorAll("input:invalid");

    invalidFields.forEach((field) => {
      if (!field.disabled) {
        field.focus();
        isValid = false;
      }
    });

    return isValid;
  }

  extractError({ errors, type }) {
    if (!errors || !Array.isArray(errors)) return;

    const foundError = errors.find(
      (error) => error.type.toLowerCase() === type.toLowerCase()
    );

    return foundError?.detail?.replace(".", " ");
  }

  setFieldMasks() {
    $(".zip_field").mask("00000");
    $(".phone_field").mask("(000) 000-0000");
    $(".dob_field").mask("00/00/0000");
    $(".cvv_field").mask("0000");

    if ($(".expiry_field").length) {
      new Cleave(".expiry_field", {
        date: true,
        datePattern: ["m", "y"],
      });
    }

    if ($(".cc_field").length) {
      new Cleave(".cc_field", {
        creditCard: true,
      });
    }
  }

  duplicateAddress() {
    const checkbox = $("#checkout_form_replicate_ship_address");

    const shipAddress = {
      address1: $("#checkout_form_ship_address_attributes_address1"),
      address2: $("#checkout_form_ship_address_attributes_address2"),
      city: $("#checkout_form_ship_address_attributes_city"),
      state: $("#checkout_form_ship_address_attributes_state_id"),
      zipcode: $("#checkout_form_ship_address_attributes_zipcode"),
    };

    const billAddress = {
      address1: $("#checkout_form_bill_address_attributes_address1"),
      address2: $("#checkout_form_bill_address_attributes_address2"),
      city: $("#checkout_form_bill_address_attributes_city"),
      state: $("#checkout_form_bill_address_attributes_state_id"),
      zipcode: $("#checkout_form_bill_address_attributes_zipcode"),
    };

    if (checkbox.is(":checked")) {
      Object.keys(shipAddress).forEach((key) => {
        billAddress[key].val(shipAddress[key].val());
      });
    } else {
      Object.keys(billAddress).forEach((key) => {
        billAddress[key].val("");
      });
    }
  }
}
