import ApplicationController from './application_controller';

export default class AlertsController extends ApplicationController {
  static targets = ['successAlert', 'errorAlert'];

  connect() {
    super.connect();
  }

  successAlertTargetConnected() {
    $(this.successAlertTarget)
      .fadeTo(5000, 500)
      .slideUp(500, this.dispatchAlertRemovedEvent);
  }

  errorAlertTargetConnected() {
    $(this.errorAlertTarget)
      .fadeTo(5000, 500)
      .slideUp(500, this.dispatchAlertRemovedEvent);
  }

  dismissAlert() {
    this.element.remove();
    this.dispatchAlertRemovedEvent();
  }

  dispatchAlertRemovedEvent(name = 'flash-msg-removed') {
    window.dispatchEvent(new Event(name));
  }
}
