import { DeleteConfirmationController } from "../../utilities/delete_confirmation_controller.js";

export default class extends DeleteConfirmationController {
  connect() {
    super.connect();
  }

  delete(event) {
    event.preventDefault();
    event.stopPropagation();

    const ok = super.deleteConfirmation("question");
    if (!ok) return false;

    const el = event.currentTarget;
    const sgid = el.dataset.sgid;

    this.callStimulusAction({
      cb: () => {
        this.stimulate("Sortable#remove_from_list", sgid);
      },
    });
  }
}
