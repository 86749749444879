import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.waitForSrc(() => {
      this.initKoreAi();
    });
  }

  initKoreAi() {
    const jwt = this.data.get('jwt');
    if (jwt) {
      KoreSDK.chatConfig.botOptions.botInfo.customData = {
        jwt: `${jwt}`,
      };
    }
    KoreSDK.chatConfig.allowLocation = false;
    KoreSDK.show(KoreSDK.chatConfig);
  }

  waitForSrc(callback) {
    console.log('ran');
    if (window.KoreSDK) {
      callback();
    } else {
      setTimeout(() => {
        this.waitForSrc(callback);
      }, 50);
    }
  }
}
