import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.trackEvent();
  }

  trackEvent() {
    const { pagePath, pageLocation, pageTitle, userId } = this.element.dataset;
    const dataLayerPageViewObj                          = {
      'event':         'pageView',
      'page_path':     pagePath,
      'page_location': pageLocation,
      'page_title':    pageTitle,
    };

    if (userId) {
      dataLayerPageViewObj['userId'] = +userId;
    }

    window.dataLayer.push(dataLayerPageViewObj);
  }
}
