import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  static targets = ['menu'];

  connect() {
    if (this.hasMenuTarget) {
      this.menuTarget.classList.add('-translate-x-full');
    }
  }

  toggle() {
    if (this.menuTarget.classList.contains('-translate-x-full')) {
      this.menuTarget.classList.remove('-translate-x-full', 'opacity-0');
      this.menuTarget.classList.add('translate-x-0', 'opacity-100');
    } else {
      this.menuTarget.classList.remove('translate-x-0', 'opacity-100');
      this.menuTarget.classList.add('-translate-x-full', 'opacity-0');
    }
  }
}
