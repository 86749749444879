import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.trackEvent();
  }

  trackEvent() {
    const { userId } = this.element.dataset;

    const dataLayerPageViewObj = {
      event: 'view_item_list',
      userId: +userId,
      user_type: 'prospect',
      ecommerce: {
        items: this.items(),
      },
    };

    window.dataLayer.push(dataLayerPageViewObj);
  }

  items() {
    const items = JSON.parse(this.element.dataset.items);
    return items.map((item, index) => ({
      item_name: item.name,
      item_id: +item.product_id,
      price: parseFloat(item.price),
      item_brand: 'Rugiet',
      item_category: 'Rugiet Ready',
      item_variant: +item.id,
      item_list_id: 'select_your_quantity',
      item_list_name: 'select your quantity',
      index,
      quantity: 1,
    }));
  }
}
