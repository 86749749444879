import ApplicationController from '../../application_controller';
// https://yarnpkg.com/package/select2
// https://select2.org
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';

export default class extends ApplicationController {
  connect() {
    $(this.element).select2();

    // Set selected states/countries in select dropdown for Spree::Zone records
    // Not set by default because :state_ids and :country_ids are not attributes
    // https://select2.org/programmatic-control/add-select-clear-items#selecting-options
    const selectedIds = JSON.parse(this.element.dataset.selectedIds);
    $(this.element).val(selectedIds);
    $(this.element).trigger('change');
  }
}
