import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  initialize() {
    if (this.element.dataset.orderCompleted === 'true') {
      window.location = '/user_verifications';
    }
  }
}
