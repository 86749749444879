import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  trackEvent() {
    const {
      userId,
      price,
      itemName,
      itemId,
      itemVariant,
      quantity,
      subscriptionType,
      orderTotal,
      promoCodeDiscount,
      subscriptionAdjustment,
      promoCode,
      paymentMethod,
    } = this.element.dataset;

    let dataLayerPageViewObj = {
      event: 'add_shipping_info',
      userId: +userId,
      user_type: 'prospect',
      ecommerce: {
        currency: 'USD',
        value: +orderTotal,
        coupon: promoCode,
        coupon_discount: +promoCodeDiscount,
        subscription_adjustment: this.subscriptionAdjustmentNumberLabel(+subscriptionAdjustment),
        shipping_tier: 'Free',
        items: [
          {
            item_name: itemName,
            item_id: +itemId,
            price: parseFloat(price),
            item_brand: 'Rugiet',
            item_category: 'Rugiet Ready',
            item_category2: subscriptionType,
            item_variant: +itemVariant,
            item_list_id: 'shipping_and_payment_form',
            item_list_name: 'shipping and payment form',
            index: 0,
            quantity: +quantity,
          },
        ],
      },
    };

    window.dataLayer.push(dataLayerPageViewObj);

    dataLayerPageViewObj = {
      event: 'add_payment_info',
      userId: +userId,
      ecommerce: {
        currency: 'USD',
        value: +orderTotal,
        coupon: promoCode,
        coupon_discount: +promoCodeDiscount,
        subscription_adjustment: this.subscriptionAdjustmentNumberLabel(+subscriptionAdjustment),
        payment_method: paymentMethod,
        items: [
          {
            item_name: itemName,
            item_id: +itemId,
            price: parseFloat(price),
            item_brand: 'Rugiet',
            item_category: 'Rugiet Ready',
            item_category2: subscriptionType,
            item_variant: +itemVariant,
            item_list_id: 'shipping_and_payment_form',
            item_list_name: 'shipping and payment form',
            index: 0,
            quantity: +quantity,
          },
        ],
      },
    };

    window.dataLayer.push(dataLayerPageViewObj);
  }

  subscriptionAdjustmentNumberLabel(amount) {
    const amountNumber = +amount;

    if (amount > 0) {
      return `$${amount} Added`;
    } if (amount < 0) {
      return `$${amount} Deducted`;
    }
    return 'None';
  }
}
