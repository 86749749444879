export function scrollToElement(pageElement) {
  let positionX = 0,
      positionY = 0;

  while (pageElement != null) {
    positionX += pageElement.offsetLeft;
    positionY += pageElement.offsetTop;
    pageElement = pageElement.offsetParent;

    try {
      window.scrollTo({ left: positionX, top: positionY, behavior: 'smooth' });
    } catch (_e) {
      window.scrollTo(positionX, positionY);
    }
  }
}
