import { DeleteConfirmationController } from "../../utilities/delete_confirmation_controller.js";

export default class extends DeleteConfirmationController {
  connect() {
    super.connect();
  }

  delete(event) {
    event.preventDefault();

    const ok = super.deleteConfirmation("state");
    if (!ok) return false;

    const el = event.currentTarget;
    const id = el.dataset.id;
    const direction = el.dataset.direction;
    const page = el.dataset.page;

    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          "Admin::States::StateTable#delete_state",
          id,
          direction,
          page
        );
      },
    });
  }
}
