import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.trackEvent();
  }

  trackEvent() {
    const { userId, user_type } = this.element.dataset;
    const dataLayerPageViewObj = {
      event: 'login',
      userId: +userId,
      user_type,
    };

    window.dataLayer.push(dataLayerPageViewObj);
  }
}
