import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  static targets = [
    'form',
    'password',
    'confirmPassword',
    'passwordIcon',
    'confirmPasswordIcon',
    'phoneField',
    'dobField',
    'zipField',
  ];

  connect() {
    super.connect();
  }

  initialize() {
    if (this.hasFormTarget) {
      $(this.formTarget).on('submit', (e) => {
        e.preventDefault();
        $(this.phoneFieldTargets).unmask();
        this.formTarget.submit();
      });
    }

    if (this.hasDobFieldTarget) {
      $(this.dobFieldTarget).mask('00/00/0000');
    }

    if (this.hasPhoneFieldTarget) {
      $(this.phoneFieldTargets).mask('(000) 000-0000');
    }

    if (this.hasZipFieldTarget) {
      $(this.zipFieldTarget).mask('00000');
    }

    if (this.element.dataset.eyeSlashSrc) {
      this.eyeSlashSrc = this.element.dataset.eyeSlashSrc;
    }

    if (this.element.dataset.eyeSrc) {
      this.eyeSrc = this.element.dataset.eyeSrc;
    }
  }

  togglePassword() {
    this.toggleInput(this.passwordTarget, this.passwordIconTarget);
  }

  toggleConfirmPassword() {
    this.toggleInput(
      this.confirmPasswordTarget,
      this.confirmPasswordIconTarget,
    );
  }

  toggleInput(input, img) {
    if (input.type === 'password') {
      input.type = 'text';
      img.src = img.dataset.visible;
    } else {
      input.type = 'password';
      img.src = img.dataset.blocked;
    }

    input.focus();
  }

  toggleSecureField(event) {
    const toggleElement = event.currentTarget;
    const field = document.getElementById(toggleElement.dataset.fieldId);

    if (field.type === 'password') {
      field.type = 'text';
      if (this.eyeSrc) toggleElement.src = this.eyeSrc;
    } else {
      field.type = 'password';
      if (this.eyeSlashSrc) toggleElement.src = this.eyeSlashSrc;
    }

    field.focus();
  }
}
