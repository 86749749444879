import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();

    document.addEventListener('unauthorizedReflexAccess', this.showUnauthorizedModal.bind(this));
  }

  disconnect() {
    document.removeEventListener('unauthorizedReflexAccess', this.showUnauthorizedModal.bind(this));
  }

  showUnauthorizedModal() {
    this.callStimulusAction({
      cb: () => {
        this.stimulate('Admin::UnauthorizedReflex#show');
      }
    })
  }
}
