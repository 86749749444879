import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static targets = ["permissions", "permission", "chevron"];

  connect() {
    super.connect();
  }

  togglePermissions() {
    if (!this.hasPermissionsTarget) return;
    this.permissionsTarget.classList.toggle("hidden");
    this.chevronTarget.classList.toggle("rotate-[-90deg]");
  }

  removePermission() {
    this.permissionTarget.remove();
  }
}
