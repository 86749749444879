import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "requiredQuestionnaire",
    "questionnaireCol",
    "questionnaireList",
  ];

  connect() {
    super.connect();

    if (
      this.hasRequiredQuestionnaireTarget &&
      this.requiredQuestionnaireTarget.checked
    ) {
      this.toggleRequiredQuestionnaire();
    }
  }

  toggleRequiredQuestionnaire() {
    this.questionnaireColTarget.classList.toggle("hidden");
    this.questionnaireListTarget.required =
      !this.questionnaireListTarget.required;
  }
}
