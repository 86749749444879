import ApplicationController from '../application_controller';

export class DeleteConfirmationController extends ApplicationController {
  connect() {
    super.connect();
  }

  deleteConfirmation(resource) {
    return confirm(`Are you sure you'd like to delete this ${resource}?`);
  }
}
