import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  initialize() {
    $(this.element).select2({
      templateSelection: this.formatState,
      minimumResultsForSearch: Infinity,
    });
  }

  formatState(state) {
    if (!state.id) {
      return state.text;
    }

    if (window.location.pathname !== state.id) {
      window.location = state.id;
    }

    const $state = $(
      '<span class="text-link-active font-bold uppercase leading-[46px] px-4"><span></span></span>'
    );

    $state.find("span").text(state.text);

    return $state;
  }
}
