import cableReady from 'cable_ready';
import ApplicationController from '../../application_controller';
import consumer from '../../../channels/consumer';

export default class extends ApplicationController {
  EVENT_NAME = 'cp:pharmacists:log:print_labels';

  static targets = ['printLabelArea'];

  connect() {
    super.connect();

    consumer.subscriptions.create(
      'PharmacyEventsChannel',
      {
        received: (data) => {
          if (data.cableReady) cableReady.perform(data.operations);
        },
      },
    );

    document.addEventListener(this.EVENT_NAME, this.reloadPrintLabels.bind(this));
    this.autoReload();
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener(this.EVENT_NAME, this.reloadPrintLabels.bind(this));
  }

  // Triggered when the CableReady event for this controller is received
  // Replace the loading button with the new print labels button
  reloadPrintLabels(event) {
    this.printLabelAreaTarget.innerHTML = event.detail.html;
  }

  // Only triggered if the loading button is visible
  // Reload the page every 5 seconds to check if the new labels are now available
  //
  // Sometimes the generation of the labels happens so quickly, CableReady dispatches the
  // event before the page is fully loaded and connected to ActionCable.
  autoReload() {
    const loadingButton = this.printLabelAreaTarget.querySelector('.loading_button_with_spinner');
    if (!loadingButton) return;

    setTimeout(() => {
      if (document.querySelector('.loading_button_with_spinner')) {
        window.location.reload();
      }
    }, 10000);
  }
}
