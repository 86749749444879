import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["template", "states", "role", "add_item", "addLicenseBtn"];

  connect() {
    super.connect();
  }

  add_state_association() {
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    );
    this.statesTarget.insertAdjacentHTML("beforeend", content);
    $(this.addLicenseBtnTarget).hide();
  }

  remove_state_association(event) {
    const states = document.querySelectorAll(".licensed_state");

    if (states.length === 1) {
      $(this.addLicenseBtnTarget).show();
    }

    let wrapper = event.target.closest(".licensed_state");

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy]").value = 1;
      wrapper.style.display = "none";
      wrapper.classList.remove("licensed_state");
    }
  }

  add_association(event) {
    event.preventDefault();
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content);
  }

  remove_association(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = 'none';
  }
}
