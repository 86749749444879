import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = [
    "nestedSidebar",
    "nestedSidebarIcon",
    "nestedSidebarContainer",
  ];

  connect() {
    super.connect();
  }

  initialize() {
    window.addEventListener("scroll", this.handleScroll.bind(this), {
      passive: true,
    });
  }

  handleScroll() {
    const header = document.querySelector("header");

    const headerBounds = header.getBoundingClientRect();

    if (window.scrollY > headerBounds.bottom) {
      this.nestedSidebarTarget.classList.add("top-0");
    } else if (window.scrollY === 0) {
      this.nestedSidebarTarget.classList.remove("top-0");
    }
  }

  toggle() {
    this.nestedSidebarTarget.classList.toggle("w-7");
    this.nestedSidebarTarget.classList.toggle("w-56");
    this.nestedSidebarContainerTarget.classList.toggle("opacity-0");
    this.nestedSidebarContainerTarget.classList.toggle("delay-100");
    this.nestedSidebarIconTarget.classList.toggle("rotate-180");
  }
}
