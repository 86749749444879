// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers";
import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";

const application = Application.start();
const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));
application.consumer = consumer;
StimulusReflex.initialize(application, { consumer });
StimulusReflex.debug = process.env.RAILS_ENV === "development";

// https://github.com/adrienpoly/stimulus-flatpickr#basic-usage
// Import Flatpickr
import Flatpickr from 'stimulus-flatpickr';
// Import style for flatpickr
require('flatpickr/dist/themes/airbnb.css');
// Manually register Flatpickr as a stimulus controller
application.register('flatpickr', Flatpickr);
