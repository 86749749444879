import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  static targets = ['textArea'];

  renderTabSuccess() {
    this.hideReplies();
  }

  createNoteSuccess(element) {
    const viewRepliesSpan = document.querySelector(`span[data-meta-id="${element.dataset.rootConversationId}"]`);
    viewRepliesSpan.dataset.hideReplies = 'false';
    viewRepliesSpan.querySelector('span').classList.remove('rotate-180');

    this.hideReplies(`:not([data-meta-id="${element.dataset.rootConversationId}"])`);
  }

  connect() {
    super.connect();
    this.leadId = this.element.dataset.leadId;
    this.activeTab = document.querySelector('[data-tab=email]');
    this.hideReplies();
  }

  disconnect() {
    super.disconnect();
  }

  submitNote(event) {
    event.preventDefault();
    event.stopPropagation();

    this.stimulate(
      'Admin::Crm::LeadReflex#create_note',
      event.target,
      this.activeTab.dataset.tab,
      this.textAreaTarget.value,
    );
  }

  cancelNote(event) {
    event.target.closest('form').remove();
  }

  selectTab(event) {
    // deactivate old tab
    this.activeTab.classList.remove('text-blue-700', 'border-blue-700');

    // activate new tab & stimulate reflex
    this.activeTab = event.target;
    this.activeTab.classList.add('text-blue-700', 'border-blue-700');
    this.stimulate('Admin::Crm::LeadReflex#render_tab', this.leadId, this.activeTab.dataset.tab)
      .then(() => {
        // remove "add note" and "reply" actions when viewing the "All Comms" tab
        // this tab should be view only
        if (this.activeTab.dataset.tab === 'comms') {
          document.querySelectorAll("div[data-reflex='click->Admin::Crm::LeadReflex#new_note_form']").forEach((el) => el.remove());
          document.querySelectorAll("div[data-reflex='click->Admin::Crm::LeadReflex#reply_to_email']").forEach((el) => el.remove());
        }
      });
  }

  hideReplies(selectors = '') {
    const query = `div[id*="box-container-"][data-has-replies="true"]${selectors}`;
    const rootConversationsWithReplies = document.querySelectorAll(query);
    rootConversationsWithReplies.forEach((conversation) => {
      const replies = conversation.querySelectorAll('div[id*="box-container-"]');
      replies.forEach((reply) => reply.classList.add('hidden'));
    });
  }
}
