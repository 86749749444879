import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  static targets = [
    'address1',
    'address2',
    'city',
    'stateId',
    'zipcode',
    'form',
    'submitBtn',
    'addressTouched',
    'lookupValidationSkipped',
  ];

  validStates = this.stateIdTarget.dataset.validStates;

  connect() {
    super.connect();
    this.addInputEventListeners();
    this.addressTouchedTarget.value = false;
    this.toggleButtonEnabled();
    this.checkedWithSmarty = false;
  };

  disconnect() {
    super.disconnect();
    this.removeInputEventListeners();
  };

  validateAddress(event) {
    event?.preventDefault();
    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          `Shared::ShipAddress#validate`,
          this.address1Target.value,
          this.address2Target.value,
          this.cityTarget.value,
          this.stateIdTarget.value,
          this.zipcodeTarget.value,
          this.checkedWithSmarty || false
        );
      }
    });
  };

  submitForm(event) {
    event?.preventDefault();
    if (this.checkedWithSmarty) {
      this.formTarget.submit();
    } else {
      this.validateAddress();
    }
  };

  clearRepeatAddressValidation() {
    this.lookupValidationSkippedTarget.value = false;
    this.checkedWithSmarty = false;
    this.toggleButtonEnabled();
  };

  touched() {
    return this.addressTouchedTarget.value === 'true';
  }

  toggleButtonEnabled() {
    this.touched() && this.addressValuesPresent() ? this.enableSubmit() : this.disableSubmit();
  }

  addressValuesPresent() {
    return this.formInputs().every(element => element.value);
  }

  formInputs() {
    return [
      this.address1Target,
      this.cityTarget,
      this.stateIdTarget,
      this.zipcodeTarget
    ].filter(element => element)
  }

  enableSubmit() {
    this.submitBtnTarget.classList.remove('cursor-not-allowed');
    this.submitBtnTarget.removeAttribute('disabled');
  }

  disableSubmit() {
    this.submitBtnTarget.classList.add('cursor-not-allowed');
    this.submitBtnTarget.setAttribute('disabled', 'disabled');
  }

  reset() {
    this.checkedWithSmarty = false;
    this.lookupValidationSkippedTarget.value = false;
    this.toggleButtonEnabled();
  }

  bypassValidation() {
    this.checkedWithSmarty = true;
    this.lookupValidationSkippedTarget.value = true;
    this.submitForm();
  }

  validatedSubmit() {
    this.checkedWithSmarty = true;
    this.lookupValidationSkippedTarget.value = false;
    this.submitForm();
  }

  enableSubmit() {
    this.submitBtnTarget.classList.remove('cursor-not-allowed');
    this.submitBtnTarget.removeAttribute('disabled');
  }

  disableSubmit() {
    this.submitBtnTarget.classList.add('cursor-not-allowed');
    this.submitBtnTarget.setAttribute('disabled', 'disabled');
  }

  displayAddressErrors(event) {
    const parentOfFieldElement = this.address1Target.parentNode;
    const { errorMessage } = event.detail;

    this.address1Target.focus();
    parentOfFieldElement.querySelector('p.text-error').innerHTML = errorMessage;
  }

  onChange() {
    this.addressTouchedTarget.value = true;
    this.checkedWithSmarty = false;
    this.lookupValidationSkippedTarget.value = false;
    this.toggleButtonEnabled()
  }

  onInput() {
    this.addressTouchedTarget.value = true;
    this.checkedWithSmarty = false;
    this.lookupValidationSkippedTarget.value = false;
    this.toggleButtonEnabled()
  }

  addInputEventListeners() {
    this.submitBtnTarget.addEventListener('click', this.submitForm.bind(this));

    document.addEventListener(
      `shared-address-disable-submit`,
      this.disableSubmit.bind(this),
    );

    document.addEventListener(
      `shared-address-enable-submit`,
      this.enableSubmit.bind(this),
    );

    document.addEventListener(
      `shared-address-validation-submit`,
      this.validatedSubmit.bind(this),
    );

    document.addEventListener(
      `shared-address-validation-bypass`,
      this.bypassValidation.bind(this),
    );

    document.addEventListener(
      `shared-address-validation-display-address-errors`,
      this.displayAddressErrors.bind(this),
    );

    document.addEventListener(
      `shared-address-validation-reset`,
      this.reset.bind(this),
    );

    this.formInputs().forEach((input) => {
      input.addEventListener('change', this.onChange.bind(this));
      input.addEventListener('input', this.onInput.bind(this));
    });
    this.address2Target.addEventListener('change', this.onChange.bind(this));
    this.address2Target.addEventListener('input', this.onInput.bind(this));
  }

  removeInputEventListeners() {
    this.submitBtnTarget.removeEventListener('click', this.submitForm.bind(this));

    document.removeEventListener(
      `shared-address-validation-submit`,
      this.validatedSubmit.bind(this),
    );

    document.removeEventListener(
      `shared-address-validation-bypass`,
      this.bypassValidation.bind(this),
    );

    document.removeEventListener(
      `shared-address-validation-display-address-errors`,
      this.displayAddressErrors.bind(this),
    );

    this.formInputs().forEach((input) => {
      input.removeEventListener('change', this.onChange.bind(this));
      input.removeEventListener('input', this.onInput.bind(this));
    });
    this.address2Target.removeEventListener('change', this.onChange.bind(this));
    this.address2Target.removeEventListener('input', this.onInput.bind(this));
  }
}

