import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = ['sendTo', 'phiMessage', 'fileInput', 'imgPreviewContainer', 'fileInputLabel'];

  connect() {
    super.connect();
    if (this.hasSendToTarget) {
      this.sendToTarget.addEventListener('change', this.togglePhiMessage.bind(this));
      this.sendToTarget.addEventListener('change', this.toggleFileUpload.bind(this));
    }
  }

  disconnect() {
    super.disconnect();
    if (this.hasSendToTarget) {
      this.sendToTarget.removeEventListener('change', this.togglePhiMessage.bind(this));
      this.sendToTarget.removeEventListener('change', this.toggleFileUpload.bind(this));
    }
  }

  togglePhiMessage() {
    if (+this.sendToTarget.value === 2) {
      this.phiMessageTarget.classList.add('hidden');
    } else {
      this.phiMessageTarget.classList.remove('hidden');
    }
  }

  toggleFileUpload() {
    const fileUploadContainer = document.getElementById('file-upload-container');
    if (!fileUploadContainer) return;

    if (+this.sendToTarget.value === 2) {
      fileUploadContainer.style.display = 'block';
      this.fileInputTarget.disabled = false;
    } else {
      fileUploadContainer.style.display = 'none';
      this.fileInputTarget.disabled = true;
    }
  }

  previewFiles() {
    const { files } = this.fileInputTarget;
    if (files.length === 0) return;

    this.imgPreviewContainerTarget.classList.remove('hidden');

    Array.from(files).forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        this.buildImagePreview(file, reader);
      };
      reader.onerror = (e) => {
        this.showToast({
          text: `Error reading file: ${e.toString()}`,
          type: 'error',
          duration: 5000,
        });
      };
      reader.readAsDataURL(file);
    });

    this.imgPreviewContainerTarget.closest('.collapsible-content').style.maxHeight = '600px';
  }

  buildImagePreview(file, reader) {
    const [thumbnailContainer, fallbackContainer, image] = this.createThumbnail(file, reader);
    const removeIcon = this.addRemoveIcon(file, thumbnailContainer);

    if (image) thumbnailContainer.appendChild(image);
    if (fallbackContainer) thumbnailContainer.appendChild(fallbackContainer);
    thumbnailContainer.appendChild(removeIcon);
    this.imgPreviewContainerTarget.appendChild(thumbnailContainer);
  }

  createThumbnail(file, reader) {
    let fallbackContainer;
    let image;
    const div = document.createElement('div');
    div.className = 'thumbnail-container flex flex-col items-center';

    if (file.type === 'image/heic' || file.type === 'image/heif') {
      fallbackContainer = document.createElement('div');
      fallbackContainer.className = 'border border-black rounded-md col-span-1 flex align-center items-center h-[60px] md:h-[80px] w-[60px] md:w-[80px]';
      fallbackContainer.innerHTML = "<p class='text-center text-xs'>Preview not Available</p>";
    } else {
      image = document.createElement('img');
      image.className = 'p-1 border border-black rounded-md col-span-1 flex place-self-center h-[60px] md:h-[80px] w-[60px] md:w-[80px]';
      image.src = reader.result;
    }

    return [div, fallbackContainer, image];
  }

  addRemoveIcon(file, thumbnailContainer) {
    const removeIcon = document.createElement('i');
    removeIcon.className = 'hover:cursor-pointer fas fa-times-circle block text-xl mt-px -my-1';
    removeIcon.onclick = () => {
      this.removeFileFromFileList(file);
      thumbnailContainer.remove();
      this.updateAfterRemoval();
    };

    return removeIcon;
  }

  updateAfterRemoval() {
    if (this.imgPreviewContainerTarget.querySelector('.thumbnail-container')) return;

    this.imgPreviewContainerTarget.classList.add('hidden');
    this.fileInputLabelTarget.classList.remove('hidden');
    this.imgPreviewContainerTarget.closest('.collapsible-content').style.maxHeight = '600px';
  }

  validateFiles() {
    const { files } = this.fileInputTarget;
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'heic', 'heif'];
    const sizeLimit = 5 * 1024 * 1024; // 5 megabytes

    if (files.length > 10) {
      this.showToast({
        text: 'Please select 10 or fewer files.',
        type: 'error',
        duration: 5000,
      });
      this.clearFileInputValue();
      return;
    }

    const invalidFile = Array.from(files).find((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const isInvalidType = !allowedExtensions.includes(fileExtension);
      const isOversized = file.size > sizeLimit;

      if (isInvalidType) {
        this.showToast({
          text: 'One of your files has an invalid file type. Please select files with a valid type.',
          type: 'error',
          duration: 5000,
        });
      } else if (isOversized) {
        this.showToast({
          text: 'One of your files exceeds 5 MB in size. Please select files smaller than 5 MB.',
          type: 'error',
          duration: 5000,
        });
      }

      return isInvalidType || isOversized;
    });

    if (invalidFile) this.clearFileInputValue();
  }

  clearFileInputValue() {
    this.fileInputTarget.value = '';
  }

  removeFileFromFileList(fileToRemove) {
    const dt = new DataTransfer();
    const { files } = this.fileInputTarget;

    Array.from(files).forEach((file) => {
      if (file.name !== fileToRemove.name) {
        dt.items.add(file);
      }
    });

    this.fileInputTarget.files = dt.files;
  }

  removeFilePreviews() {
    this.imgPreviewContainerTarget.querySelectorAll('.thumbnail-container').forEach((thumbnail) => thumbnail.remove());
    this.imgPreviewContainerTarget.classList.add('hidden');
    if (this.fileInputTarget.files.length) this.fileInputLabelTarget.classList.add('hidden');
  }

  toggleCollapse(event) {
    const content = event.currentTarget.parentElement.querySelector('.collapsible-content');
    const chevron = event.currentTarget.querySelector('img');

    if (content.classList.contains('expanded')) {
      content.style.maxHeight = '0';
    } else {
      content.style.maxHeight = `${content.scrollHeight}px`;
    }

    chevron.classList.toggle('rotate-180');
    content.classList.toggle('expanded');
  }

  promoUpdate() {
    const promoCode = document.getElementById('promo_code').value;
    if (!!promoCode) {
      this.callStimulusAction({
        cb: () => {
          this.stimulate('Store::MembersPortal::PromoUpdateReflex#update', promoCode);
        },
      });
    }
  }
}
