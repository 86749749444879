import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.trackEvent();
  }

  trackEvent() {
    try {
      window.zESettings  = {
        analytics: false
      };
      window.dataLayer = window.dataLayer || [];
      var EVENTS_TO_SEND = ['Web Widget Opened', 'Web Widget Minimised'];
      zE('webWidget:on', 'userEvent', function (event) {
        if (event.action === event.action.indexOf(EVENTS_TO_SEND) < 0) return;

        var dataLayerPageViewObj = {
          'event':    'Zendesk_Web_Widget',
          'category': 'Contact Us',
          'action':   event.action
        };

        window.dataLayer.push(dataLayerPageViewObj);
      })
    } catch (e) {
      console.log("Zendesk Widget Error: ", e);
    }
  }
}
