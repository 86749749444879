import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  disconnect() {
    super.disconnect();
  }

  clearFields() {
    // Select all input fields that are text, textarea or select fields
    const inputs = document.querySelectorAll(
      '.clearable input[type="text"], .clearable textarea, .clearable select',
    );

    // Loop through the inputs and clear their values
    inputs.forEach((element) => {
      const input = element;
      input.value = '';
    });
  }
}
