import ApplicationController from "../../application_controller";
import $ from 'jquery';
import 'typeahead.js';
import Bloodhound from 'typeahead.js/dist/bloodhound';

export default class extends ApplicationController {
  connect() {
    this.setupTypeahead();
  }

  setupTypeahead() {
    var search_results = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/search?query=%QUERY',
        wildcard: '%QUERY'
      }
    });

    $('#search-field').typeahead(
      {
        highlight: true,
        classNames: {
          menu: 'tt-menu min-w-[427px]',
          dataset: 'tt-dataset bg-white rounded shadow-lg	divide-y divide-gray-200',
          selectable: 'p-4'
        }
      },
      {
        source: search_results,
        templates: {
          suggestion: function(data) {
            return `<ul class="hover:bg-link-active group cursor-pointer">
                    <ol class="text-sm font-medium">
                      <p class="text-sm text-gray-500 group-hover:text-white">${data.email}</p>
                    </ol>
                    <ol class="flex">
                      <p class="text-sm text-gray-500 group-hover:text-white">${data.display}</p>
                    </ol>
                    <ol class="flex">
                      <p class="text-sm text-gray-500 group-hover:text-white">${data.phone_number || ''}</p>
                    </ol>
                    <ol class="flex">
                      <p class="text-sm text-gray-500 group-hover:text-white">${data.date_of_birth || ''}</p>
                    </ol>
                  </ul>`;
          }
        },
        limit: 20,
      }
    );

    $('#search-field').bind('typeahead:select', function(ev, suggestion) {
      $(this).typeahead('val', suggestion.display);

      window.location = suggestion.url
    });
  }
}
