import ApplicationController from "../../application_controller";
import moment from "moment";

export default class AwaitingConsultationController extends ApplicationController {
  connect() {
    super.connect();
  }

  initialize() {
    const countingElements = document.querySelectorAll(".timeago");

    countingElements.forEach((element) => {
      this.counterSinceTime(element.dataset.datetime, element);
    });
  }

  counterSinceTime(date, el) {
    var theDate = moment(date).toDate();
    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;
    var element = el;
    var timer;

    function count() {
      var now = new Date();
      if (theDate > now) {
        var distance = theDate - now;
        if (distance < 0) {
          clearInterval(timer);
          return;
        }
      } else {
        var distance = now - theDate;
        if (distance < 0) {
          clearInterval(timer);
          return;
        }
      }
      var days = Math.floor(distance / _day);
      var hours = Math.floor((distance % _day) / _hour);
      if (hours < 10) {
        hours = "0" + hours;
      }
      var minutes = Math.floor((distance % _hour) / _minute);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      var seconds = Math.floor((distance % _minute) / _second);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      var daytext = "";
      if (days > 1) {
        daytext = " days ";
      } else {
        daytext = " day ";
      }
      if (days > 0) {
        element.innerHTML =
          '<span class="red-counter">' +
          '<span class="days">' +
          days +
          "d</span>" +
          "<i> </i>" +
          '<span class="hours">' +
          hours +
          "h</span>" +
          "<i> </i>" +
          '<span class="minutes">' +
          minutes +
          "m</span>" +
          "<i> </i>" +
          '<span class="seconds">' +
          seconds +
          "s</span></span>";
      } else if (hours > 0) {
        element.innerHTML =
          '<span class="hours">' +
          hours +
          "h</span>" +
          "<i> </i>" +
          '<span class="minutes">' +
          minutes +
          "m</span>" +
          "<i> </i>" +
          '<span class="seconds">' +
          seconds +
          "s</span>";
      } else if (minutes > 0) {
        element.innerHTML =
          '<span class="minutes">' +
          minutes +
          "m</span>" +
          "<i> </i>" +
          '<span class="seconds">' +
          seconds +
          "s</span>";
      } else {
        element.innerHTML = '<span class="seconds">' + seconds + "s</span>";
      }
    }

    timer = setInterval(count, 1000);
  }
}
