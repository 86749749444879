import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["preview", "fileInput", "svg", "file"];

  connect() {
    super.connect();
  }

  add() {
    const [file] = this.fileInputTarget.files;

    if (file) {
      this.previewTarget.classList.add("w-full");
      this.previewTarget.src = URL.createObjectURL(file);
      this.svgTarget.remove();
    }
  }
}
