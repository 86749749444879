import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = ['subject', 'body'];

  connect() {
    super.connect();
    this.subjectTarget.addEventListener('focus', this.toggleFocusedDataAttribute.bind(this));
    this.bodyTarget.addEventListener('focus', this.toggleFocusedDataAttribute.bind(this));
  }

  disconnect() {
    super.disconnect();
    this.subjectTarget.removeEventListener('focus', this.toggleFocusedDataAttribute.bind(this));
    this.bodyTarget.removeEventListener('focus', this.toggleFocusedDataAttribute.bind(this));
  }

  toggleFocusedDataAttribute(event) {
    const element = event.currentTarget;
    element.dataset.focused = 'true';

    if (element === this.subjectTarget) {
      this.bodyTarget.dataset.focused = 'false';
    } else if (element === this.bodyTarget) {
      this.subjectTarget.dataset.focused = 'false';
    }
  }

  // Allows for tags to be inserted into the subject or body of an email template
  onClickInsertTag(event) {
    if (this.subjectTarget.dataset.focused !== 'true' && this.bodyTarget.dataset.focused !== 'true') return;

    const tag = `{{${event.currentTarget.dataset.tag}}}`;
    const focusedEl = document.querySelector('[data-focused=true]');
    const [start, end] = [focusedEl.selectionStart, focusedEl.selectionEnd];

    focusedEl.setRangeText(tag, start, end, 'end');
    focusedEl.focus();
  }
}
