import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  static targets = [
    'number',
    'expiry',
    'verificationValue',
    'billing_zipcode',
    'addressToggle',
    'address1',
    'address2',
    'city',
    'stateId',
    'zipcode',
    'form',
    'submitBtn',
    'creditCardTouched',
    'addressTouched',
    'paymentMethodId',
    'lookupValidationSkipped',
  ];

  connect() {
    super.connect();

    document.addEventListener('shared-credit-card-rewire', this.rewire.bind(this));
    document.addEventListener('shared-credit-card-validation-disable-submit', this.toggleButtonEnabled.bind(this));
    document.addEventListener('shared-credit-card-validation-bypass', this.bypassValidation.bind(this));
    document.addEventListener('shared-credit-card-validation-reset', this.reset.bind(this));
    document.addEventListener('shared-credit-card-validation-submit', this.validatedSubmit.bind(this));

    this.addInputEventListeners();

    if (this.addressToggleTarget) {
      this.addressToggleTarget.addEventListener('change', this.loadAddressForm.bind(this));
      this.stimulate('Shared::CreditCardReflex#toggle_address', true);
    }

    if (this.submitBtnTarget) {
      this.submitBtnTarget.addEventListener('click', this.submitForm.bind(this));
    }

    this.toggleButtonEnabled();

    this.checkedWithSmarty = false;
  }

  disconnect() {
    document.removeEventListener('shared-credit-card-rewire', this.rewire.bind(this));
    document.removeEventListener('shared-credit-card-validation-disable-submit', this.toggleButtonEnabled.bind(this));
    document.removeEventListener('shared-credit-card-validation-bypass', this.bypassValidation.bind(this));
    document.removeEventListener('shared-credit-card-validation-reset', this.reset.bind(this));
    document.removeEventListener('shared-credit-card-validation-submit', this.validatedSubmit.bind(this));

    this.removeInputEventListeners();

    if (this.addressToggleTarget) {
      this.addressToggleTarget.removeEventListener('change', this.loadAddressForm.bind(this));
    }
  }

  rewire() {
    this.removeInputEventListeners();
    this.addInputEventListeners();
    this.toggleButtonEnabled();
  }

  reset() {
    this.checkedWithSmarty = false;
    this.lookupValidationSkippedTarget.value = false;
    this.toggleButtonEnabled();
  }

  bypassValidation() {
    this.checkedWithSmarty = true;
    this.lookupValidationSkippedTarget.value = true;
    this.submitForm();
  }

  validatedSubmit() {
    this.checkedWithSmarty = true;
    this.lookupValidationSkippedTarget.value = false;
    this.submitForm();
  }

  submitForm(event) {
    event?.preventDefault();
    if ((!this.hasAddressToggleTarget || this.addressToggleTarget.checked) && !this.checkedWithSmarty) {
      this.verifyAddress();
    } else {
      this.formTarget.submit();
    }
  }

  valuesPresent() {
    const creditCardPresent = this.creditCardValuesPresent();
    const billAddressPresent = this.billAddressValuesPresent();
    return creditCardPresent && billAddressPresent;
  }

  addressTouched() {
    return this.hasAddressTouchedTarget && this.addressToggleTarget.checked && this.addressTouchedTarget.value === 'true';
  }

  creditCardTouched() {
    return this.creditCardTouchedTarget.value === 'true';
  }

  touched() {
    return this.addressTouched() || this.creditCardTouched();
  }

  creditCardValuesPresent() {
    return this.creditCardInputs().every((element) => element.value);
  }

  billAddressValuesPresent() {
    return this.addressInputs().every((element) => element.value);
  }

  formInputs() {
    if (this.addressToggleTarget.checked) {
      return [...this.creditCardInputs(), ...this.addressInputs()];
    }
    return [...this.creditCardInputs()];
  }

  creditCardInputs() {
    const inputs = [];
    if (this.hasNumberTarget) {
      inputs.push(this.numberTarget);
    }
    if (this.hasVerificationValueTarget) {
      inputs.push(this.verificationValueTarget);
    }
    if (this.hasExpiryTarget) {
      inputs.push(this.expiryTarget);
    }
    if (this.hasZipcodeTarget) {
      inputs.push(this.zipcodeTarget);
    }
    return inputs;
  }

  addressInputs() {
    const inputs = [];
    if (this.hasAddress1Target) {
      inputs.push(this.address1Target);
    }
    if (this.hasAddress2Target) {
      inputs.push(this.address2Target);
    }
    if (this.hasCityTarget) {
      inputs.push(this.cityTarget);
    }
    if (this.hasStateIdTarget) {
      inputs.push(this.stateIdTarget);
    }
    if (this.hasZipcodeTarget) {
      inputs.push(this.zipcodeTarget);
    }
    return inputs;
  }

  loadAddressForm(event) {
    event?.preventDefault();
    const { checked } = event.currentTarget;
    this.removeInputEventListeners();
    this.stimulate('Shared::CreditCardReflex#toggle_address', checked);
    super.connect();
  }

  verifyAddress() {
    return this.callStimulusAction.bind(this)({
      cb: () => {
        this.stimulate(
          'Shared::CreditCardReflex#validate',
          this.address1Target.value,
          this.address2Target.value,
          this.cityTarget.value,
          this.stateIdTarget.value,
          this.zipcodeTarget.value,
          this.checkedWithSmarty || false,
        );
      },
    });
  }

  clearRepeatAddressValidation() {
    this.addressLookupBypassed = false;
    this.checkedWithSmarty = false;
    this.toggleButtonEnabled();
  }

  addInputEventListeners() {
    this.creditCardInputs().forEach((input) => {
      input.addEventListener('change', this.onCardChange.bind(this));
      input.addEventListener('input', this.onCardInput.bind(this));
    });

    this.addressInputs().forEach((input) => {
      input.addEventListener('change', this.onAddressChange.bind(this));
      input.addEventListener('input', this.onAddressInput.bind(this));
    });

  }

  removeInputEventListeners() {
    this.creditCardInputs().forEach((input) => {
      input.removeEventListener('change', this.onCardChange.bind(this));
      input.removeEventListener('input', this.onCardInput.bind(this));
    });

    this.addressInputs().forEach((input) => {
      input.removeEventListener('change', this.onAddressChange.bind(this));
      input.removeEventListener('input', this.onAddressInput.bind(this));
    });
  }

  toggleButtonEnabled() {
    const cardReady = this.creditCardTouched() && this.creditCardValuesPresent();
    const addressReady = this.addressTouched() && this.billAddressValuesPresent();
    if (cardReady || addressReady) {
      this.enableSubmit();
    } else {
      this.disableSubmit();
    }
  }

  enableSubmit() {
    this.submitBtnTarget.classList.remove('cursor-not-allowed', 'bg-gray-200');
    this.submitBtnTarget.removeAttribute('disabled');
  }

  disableSubmit() {
    this.submitBtnTarget.classList.add('cursor-not-allowed', 'bg-gray-200');
    this.submitBtnTarget.setAttribute('disabled', 'disabled');
  }

  onCardChange() {
    this.creditCardTouchedTarget.value = 'true';
    this.toggleButtonEnabled();
  }

  onCardInput() {
    this.creditCardTouchedTarget.value = 'true';
    this.toggleButtonEnabled();
  }

  onAddressChange() {
    if (this.hasAddressTouchedTarget) {
      this.addressTouchedTarget.value = 'true';
    }
    this.toggleButtonEnabled();
  }

  onAddressInput() {
    if (this.hasAddressTouchedTarget) {
      this.addressTouchedTarget.value = 'true';
    }
    this.toggleButtonEnabled();
  }
}
