import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.trackEvent();
  }

  trackEvent() {
    const {
      userId,
      price,
      itemName,
      itemId,
      itemVariant,
      quantity,
    } = this.element.dataset;

    const dataLayerPageViewObj = {
      event: 'add_to_cart',
      userId: +userId,
      user_type: 'prospect',
      ecommerce: {
        items: [
          {
            item_name: itemName,
            item_id: +itemId,
            price: parseFloat(price),
            item_brand: 'Rugiet',
            item_category: 'Rugiet Ready',
            item_variant: +itemVariant,
            item_list_id: 'select_your_quantity',
            item_list_name: 'select your quantity',
            index: 0,
            quantity: +quantity,
          },
        ],
      },
    };

    window.dataLayer.push(dataLayerPageViewObj);
  }
}
