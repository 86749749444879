import { DeleteConfirmationController } from "../../utilities/delete_confirmation_controller.js";

export default class extends DeleteConfirmationController {
  connect() {
    super.connect();
  }

  delete(event) {
    event.preventDefault();
    event.stopPropagation();

    const ok = super.deleteConfirmation("payment method");
    if (!ok) return false;

    const el = event.currentTarget;
    const id = el.dataset.id;

    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          "Admin::PaymentMethods::Table#delete_payment_method",
          id
        );
      },
    });
  }
}
