import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.trackEvent();
  }

  trackEvent() {
    const {
      userId,
      price,
      itemName,
      itemId,
      itemVariant,
      quantity,
      subscriptionType,
      orderTotal,
      promoCodeDiscount,
      subscriptionAdjustment,
      promoCode,
      paymentMethod,
      orderId,
      sha256EmailAddress,
      sha256PhoneNumber,
      sha256FirstName,
      sha256LastName,
    } = this.element.dataset;

    const dataLayerPageViewObj = {
      event: 'purchase',
      userId: +userId,
      user_type: 'patient',
      sha256_email_address: sha256EmailAddress,
      sha256_phone_number: sha256PhoneNumber,
      sha256_first_name: sha256FirstName,
      sha256_last_name: sha256LastName,
      ecommerce: {
        transaction_id: orderId,
        currency: 'USD',
        value: +orderTotal,
        coupon: promoCode,
        coupon_discount: +promoCodeDiscount,
        subscription_adjustment: subscriptionAdjustment,
        shipping_tier: 'Free',
        shipping: 0,
        tax: 0,
        affiliation: 'Rugiet Online Store',
        payment_method: paymentMethod,
        items: [
          {
            item_name: itemName,
            item_id: +itemId,
            price: parseFloat(price),
            item_brand: 'Rugiet',
            item_category: 'Rugiet Ready',
            item_category2: subscriptionType,
            item_variant: +itemVariant,
            item_list_id: 'shipping_form',
            item_list_name: 'shipping form',
            index: 0,
            quantity: +quantity,
          },
        ],
      },
    };

    window.dataLayer.push(dataLayerPageViewObj);
  }
}
