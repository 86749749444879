import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  EVENT_NAME = 'GMT_TRACK_HARD_STOP';

  connect() {
    super.connect();

    document.addEventListener(this.EVENT_NAME, this.trackEvent.bind(this));
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener(this.EVENT_NAME, this.trackEvent.bind(this));
  }

  trackEvent() {
    const { userId } = this.element.dataset;

    const dataLayerPageViewObj = {
      event: 'med_ed_question_cannot_complete',
      question_no: 'question_0',
      question: 'User Demographics',
      question_category: 'demographics',
      userId: +userId,
      user_type: 'prospect',
    };

    window.dataLayer.push(dataLayerPageViewObj);
  }
}
