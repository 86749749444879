import ApplicationController from "../../application_controller";

export default class PharmacistsShippingController extends ApplicationController {
  static targets = ["shippingLabel"];

  connect() {
    super.connect();

    if (!this.hasShippingLabelTarget) return;

    const orderItemId = this.shippingLabelTarget.value;

    if (orderItemId) {
      const printWindow = window.open(
        `${this.shippingLabelTarget.dataset.path}.pdf`,
        "popup",
        "width=auto,height=auto"
      );

      if (printWindow) {
        printWindow.focus();
        printWindow.print();
      }
    }
  }
}
