import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = [
    'number',
    'expiry',
    'verificationValue',
    'address1',
    'city',
    'stateId',
    'zipcode',
    'submitBtn',
    'form',
  ];

  connect() {
    super.connect();
    this.addInputEventListeners();
    this.toggleButtonEnabled();
  }

  disconnect() {
    super.disconnect();
    this.removeInputEventListeners();
  }

  submitForm(event) {
    event?.preventDefault();
    this.element.removeAttribute('data-action');
    this.formTarget.submit();
  }

  formInputs() {
    return [
      this.numberTarget,
      this.expiryTarget,
      this.verificationValueTarget,
      this.address1Target,
      this.cityTarget,
      this.stateIdTarget,
      this.zipcodeTarget,
    ];
  }

  valid() {
    return this.formInputs().every((input) => input.value);
  }

  clearRepeatAddressValidation() {
    window.addressLookupBypassed = false;
    window.addressValidatedWithSmarty = false;
    this.toggleButtonEnabled();
  }

  enableSubmit() {
    this.submitBtnTarget.classList.remove('cursor-not-allowed');
    this.submitBtnTarget.removeAttribute('disabled');
  }

  disableSubmit() {
    this.submitBtnTarget.classList.add('cursor-not-allowed');
    this.submitBtnTarget.setAttribute('disabled', 'disabled');
  }

  toggleButtonEnabled() {
    if (this.valid()) {
      this.enableSubmit();
    } else {
      this.disableSubmit();
    }
  }

  addInputEventListeners() {
    this.submitBtnTarget.addEventListener('click', this.submitForm.bind(this));
    this.formInputs().forEach((input) => input.addEventListener('input', this.toggleButtonEnabled.bind(this)));
  }

  removeInputEventListeners() {
    this.submitBtnTarget.removeEventListener('click', this.submitForm.bind(this));
  }
}
