import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.trackEvent();
  }

  trackEvent() {
    const {
      userId, questionId, questionName, questionCategory, productId,
    } = this.element.dataset;

    const dataLayerPageViewObj = {
      event: 'med_ed_question_completed',
      userId: +userId,
      question_no: `question_${questionId}`,
      question: questionName,
      question_category: questionCategory,
      product_id: +productId,
      user_type: 'prospect',
    };

    window.dataLayer.push(dataLayerPageViewObj);
  }
}
