export default function (stimulus) {
  if (window.checkSessionInitiated) return;

  window.checkSessionInitiated = true;
  window.sessionModalActive = false;

  let heartBeatActivated = false;

  class HeartBeat {
    constructor() {
      $(() => {
        this.initHeartBeat();
      });
    }

    initHeartBeat() {
      this.lastActive = new Date().valueOf();

      if (!heartBeatActivated) {
        ["mousemove", "scroll", "click", "keydown"].forEach((activity) => {
          document.addEventListener(
            activity,
            (ev) => {
              this.lastActive =
                ev.timeStamp + performance.timing.navigationStart;
            },
            false
          );
        });
        heartBeatActivated = true;
      }
    }
  }

  window.heartBeat = new HeartBeat();

  const sessionTimeoutPollFrequency = 10;

  let timeout = 0;

  const pollForSessionTimeout = () => {
    if (
      Date.now() - window.heartBeat.lastActive <
      sessionTimeoutPollFrequency * 1000
    ) {
      timeout = setTimeout(
        pollForSessionTimeout,
        sessionTimeoutPollFrequency * 1000
      );
      return;
    }

    $.ajax({
      url: "/check_session_timeout",
    })
      .done((data) => {
        if (data && data.time_left) {
          if (data.time_left <= 60 && !window.sessionModalActive) {
            stimulus.stimulate(
              "Store::Modals::SessionTimeoutReflex#set_session_timeout_details",
              "session_remaining"
            );
            window.sessionModalActive = true;
          }
        } else {
          clearTimeout(timeout);
        }
      })
      .fail((data) => {
        if (data.status === 401) {
          window.sessionModalActive = false;

          stimulus.closeModal();

          stimulus.stimulate(
            "Store::Modals::SessionTimeoutReflex#set_session_timeout_details",
            "session_timed_out"
          );
        }
      });

    timeout = setTimeout(
      pollForSessionTimeout,
      sessionTimeoutPollFrequency * 1000
    );
  };

  // Begin checking 10 minutes after loading the page
  timeout = setTimeout(pollForSessionTimeout, 10 * 60 * 1000);
}
