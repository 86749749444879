import Dropzone from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  static targets = ["input"];
  url = this.inputTarget.dataset.directUploadUrl;

  connect() {
    this.dropZone = new Dropzone(this.element, this.dropZoneConfig);
    // Disable image upload if image attached
    if (this.data.get("imageAttached") === "true") this.disableImageUpload(this.dropZone.hiddenFileInput, this.element);
    this.displayExistingFile();
    this.bindEvents();
  }

  get dropZoneConfig() {
    return {
      url:            this.url,
      headers:        { "X-CSRF-Token": document.head.querySelector("[name=csrf-token]").content },
      maxFiles:       this.data.get("maxFiles") || 1,
      maxFilesize:    this.data.get("maxFileSize") || 10,
      acceptedFiles:  'image/*',
      addRemoveLinks: true,
      autoQueue:      false
    }
  }

  // Show images if already saved in S3 bucket
  displayExistingFile() {
    let dzImageUrl = this.data.get("imageUrl");
    let dzImageName = this.data.get("imageName");

    if (this.data.get("imageAttached") === "false") return;
    if (dzImageUrl === null) return;

    let mockFile = { name: dzImageName, size: 10000000 };
    this.dropZone.displayExistingFile(mockFile, dzImageUrl, null, "", false);

    // Wrap image link around dz-details div
    let dzId = "#dz-" + dzImageName;
    $(dzId + " .dz-details").wrap(`<a id='dz-image-wrapper' href='${dzImageUrl}' target="_blank" rel="noopener"></a>`);
    $(dzId + " .dz-details").css("cursor", "pointer");
  }

  bindEvents() {
    const controller = this;

    // Disable image upload if image successfully added
    this.dropZone.on("addedfile", function(file) {
      setTimeout(() => {
        if (file.status === "added") {
          new DirectUploadController(file, controller.url, controller, this).upload();
          controller.disableImageUpload(this.hiddenFileInput, this.element);
        }
      }, 500);
    });

    // Enable image upload when image is removed
    this.dropZone.on("removedfile", function(_file) {
      controller.enableImageUpload(this.hiddenFileInput, this.element);
    });
  }

  disableImageUpload(hiddenFileInput, parentElement) {
    hiddenFileInput.setAttribute("disabled", "disabled");
    parentElement.classList.add("hover:cursor-not-allowed");
    const els = parentElement.getElementsByTagName("*");
    for (const el of els) { el.classList.add("hover:cursor-not-allowed"); }
  }

  enableImageUpload(hiddenFileInput, parentElement) {
    hiddenFileInput.removeAttribute("disabled");
    parentElement.classList.remove("hover:cursor-not-allowed");
    const els = parentElement.getElementsByTagName("*");
    for (const el of els) { el.classList.remove("hover:cursor-not-allowed"); }
  }
}

class DirectUploadController {
  constructor(file, url, stimulusController, dropZone) {
    this.directUpload = new DirectUpload(file, url, this);
    this.file = file;
    this.controller = stimulusController;
    this.dropZone = dropZone;
  }

  upload() {
    this.directUpload.create((error, blob) => {
      if (error) {
        this.emitDropzoneError(error);
      } else {
        this.createHiddenInput(blob.signed_id);
        this.emitDropzoneSuccess();
      }
    });
  }

  // Create and append hidden input element to form
  createHiddenInput(value) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.controller.inputTarget.name;
    input.value = value;
    this.controller.element.closest("form").appendChild(input);
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(event) {
    const uploadProgress = (event.loaded / event.total) * 100;
    this.file.previewTemplate.querySelector(".dz-upload").style.width = `${uploadProgress}%`;
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.controller.dropZone.emit("error", this.file, error);
    this.controller.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.controller.dropZone.emit("success", this.file);
    this.controller.dropZone.emit("complete", this.file);
  }
}
