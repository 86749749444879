import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.trackEvent();
  }

  trackEvent() {
    const {
      userId,
      subscriptionType,
      subscriptionAdjustments,
      quantity,
    } = this.element.dataset;

    const dataLayerPageViewObj = {
      event: 'select_subscription',
      userId: +userId,
      subscription: subscriptionType,
      dosespermonth: quantity,
      subscription_adjustment: subscriptionAdjustments,
      user_type: 'prospect',
    };

    window.dataLayer.push(dataLayerPageViewObj);
  }
}
