import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static targets = ["imageContainer", "toggleButton", "smsMessage"];

  connect() {
    super.connect();
  }

  disconnect() {
    if (!this.imageContainerTarget.classList.contains("invisible")) {
      this.toggleId();
    }
  }

  smsMessageTargetConnected() {
    setTimeout(() => {
      $(this.smsMessageTarget).remove();
    }, 2000);
  }

  toggleId() {
    this.imageContainerTarget.classList.toggle("invisible");
    this.imageContainerTarget.classList.toggle("h-0");
    this.imageContainerTarget.classList.toggle("opacity-0");
    this.imageContainerTarget.classList.toggle("scale-0");
    this.toggleButtonText();
  }

  toggleButtonText() {
    const buttonText = this.toggleButtonTarget.innerText.toLowerCase();

    if (buttonText.includes("show")) {
      this.toggleButtonTarget.innerText = "Hide ID";
    } else {
      this.toggleButtonTarget.innerText = "Show ID";
    }
  }
}
