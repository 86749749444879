import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.trackEvent();
  }

  trackEvent() {
    const {
      userId,
      sha256EmailAddress,
      sha256FirstName,
      sha256LastName,
    } = this.element.dataset;
    const dataLayerPageViewObj = {
      event: 'sign_up',
      userId: +userId,
      user_type: 'prospect',
      sha256_email_address: sha256EmailAddress,
      sha256_first_name: sha256FirstName,
      sha256_last_name: sha256LastName,
    };

    window.dataLayer.push(dataLayerPageViewObj);
  }
}
