import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.timestamp = this.element.dataset.timestamp;
  }

  removeFlag() {

    // If record hasn't been saved, remove form from the page
    // if (this.element.dataset.newRecord === 'true') {
    //   this.element.remove();
    // } else {
    //   // Delete record from database, then remove form from page
    //   const ok = confirm("Are you sure you'd like to delete this answer?");
    //   if (!ok) return false;
    //
    //   // https://docs.stimulusreflex.com/rtfm/lifecycle#promises
    //   this.stimulate('Admin::Questionnaires::QuestionAndAnswer#delete_answer_option', this.element.dataset.recordId)
    //     // Successful record deletion
    //     .then(payload => {
    //       const { data, element, event } = payload;
    //       const { attrs, reflexId } = data;
    //       document.getElementById(`ao-${this.timestamp}`).remove();
    //     })
    //     // Error upon record deletion
    //     .catch(payload => {
    //       const { data, element, event } = payload;
    //       const { attrs, reflexId } = data;
    //       const { error } = event.detail.stimulusReflex;
    //       // TODO: handle error on record deletion
    //     });
  }
}
