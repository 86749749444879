import moment from 'moment';
import ApplicationController from '../../application_controller';

const nextOccurrenceAtId = 'store_subscriptions_subscription_next_occurrence_at';
const subTypeId = 'store_subscriptions_subscription_subscription_type_id';

export default class extends ApplicationController {
  connect() {
    super.connect();
    // Disable next refill date field if one time purchase
    if (document.getElementById(subTypeId).selectedOptions[0].dataset.monthsCount === '99') {
      this.disableNextOccurrenceAt();
    }
  }

  setNextOccurrenceAt() {
    const nextOccurrenceAt = this.nextOccurrenceAt();
    nextOccurrenceAt ? this.enableNextOccurrenceAt() : this.disableNextOccurrenceAt();
    document.getElementById(nextOccurrenceAtId).value = nextOccurrenceAt;
  }

  nextOccurrenceAt() {
    const { monthsCount } = document.getElementById(subTypeId).selectedOptions[0].dataset;
    const pausedBtn = document.getElementById('store_subscriptions_subscription_paused');
    if (pausedBtn.checked) return null;

    let nextOccurrenceAt;

    switch (monthsCount) {
      case '1': // monthly
        nextOccurrenceAt = moment().add(1, 'months').format('MM/DD/YYYY');
        break;
      case '3': // quarterly
        nextOccurrenceAt = moment().add(3, 'months').format('MM/DD/YYYY');
        break;
      case '99': // one time purchase
        nextOccurrenceAt = null;
        break;
      default:
        nextOccurrenceAt = null;
        break;
    }
    return nextOccurrenceAt;
  }

  disableNextOccurrenceAt() {
    const nextOccurrenceAt = document.getElementById(nextOccurrenceAtId);
    nextOccurrenceAt.parentNode.classList.add('hover:cursor-not-allowed');
    nextOccurrenceAt.classList.add('pointer-events-none', 'bg-gray-100');
    nextOccurrenceAt.value = null;
  }

  enableNextOccurrenceAt() {
    const nextOccurrenceAt = document.getElementById(nextOccurrenceAtId);
    nextOccurrenceAt.parentNode.classList.remove('hover:cursor-not-allowed');
    nextOccurrenceAt.classList.remove('pointer-events-none', 'bg-gray-100');
  }

  closeModal() {
    const modal = document.querySelector('[data-modal-target=modal]');
    const overlay = document.querySelector('[data-modal-target=overlay]');
    const modalTitle = document.getElementById('modal-title');
    const modalContent = document.getElementById('modal-content');

    modal.classList.remove('opacity-100', 'visible');
    modal.classList.add('opacity-0', 'invisible', 'modal');
    overlay.classList.remove('bg-opacity-75', 'visible');
    overlay.classList.add('bg-opacity-0', 'invisible');
    modalTitle.innerHTML = '';
    modalContent.innerHTML = '';
  }
}
