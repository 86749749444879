import ApplicationController from '../../../application_controller';

export default class extends ApplicationController {
  ITEM_QUESTION_POSITION = 'GTM_MED_ED_QUESTION_STEPS_QUESTION_POSITION';

  ITEM_SECTION_POSITION = 'GTM_MED_ED_QUESTION_STEPS_SECTION_POSITION';

  connect() {
    super.connect();

    if (!this.isSamePage()) this.trackEvent();
  }

  isSamePage() {
    const { sectionPosition, questionPosition } = this.element.dataset;
    const { lastSectionPosition, lastQuestionPosition } = this.parseLastPositions();

    if (sectionPosition || lastSectionPosition) {
      return +sectionPosition === +lastSectionPosition && +questionPosition === +lastQuestionPosition;
    }
    return +questionPosition === +lastQuestionPosition;
  }

  parseLastPositions() {
    let lastSectionPosition = localStorage.getItem(this.ITEM_SECTION_POSITION);
    if (lastSectionPosition) lastSectionPosition = +lastSectionPosition;

    let lastQuestionPosition = localStorage.getItem(this.ITEM_QUESTION_POSITION);
    if (lastQuestionPosition) lastQuestionPosition = +lastQuestionPosition;

    return { lastSectionPosition, lastQuestionPosition };
  }

  stepDirection(sectionPosition, questionPosition) {
    const { lastSectionPosition, lastQuestionPosition } = this.parseLastPositions();

    if (!sectionPosition && lastSectionPosition) return 'forward';
    if (sectionPosition > lastSectionPosition) return 'forward';
    if (sectionPosition > lastSectionPosition && questionPosition > lastQuestionPosition) return 'forward';
    if (!sectionPosition && questionPosition > lastQuestionPosition) return 'forward';

    return 'backward';
  }

  trackEvent() {
    const {
      userId,
      questionId,
      questionName,
      questionCategory,
      productId,
      sectionPosition,
      questionPosition,
    } = this.element.dataset;

    const dataLayerPageViewObj = {
      event: `med_ed_question_steps_${this.stepDirection(+sectionPosition, +questionPosition)}`,
      question_no: `question_${questionId}`,
      question: questionName,
      question_category: questionCategory,
      product_id: +productId,
      userId: +userId,
      user_type: 'prospect',
    };

    window.dataLayer.push(dataLayerPageViewObj);
    localStorage.setItem(this.ITEM_QUESTION_POSITION, questionPosition);
    localStorage.setItem(this.ITEM_SECTION_POSITION, sectionPosition);
  }
}
