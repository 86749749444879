import ApplicationController from "../application_controller";

export default class SearchController extends ApplicationController {
  static targets = ["search"];

  connect() {
    super.connect();
  }

  initialize() {
    const input = $(".ransack_search_form").find("form");

    $(this.searchTargets).on("click", function () {
      input.trigger("submit");
    });

    input.on("keypress", function (e) {
      if (e.key === "Enter") {
        input.trigger("submit");
      }
    });
  }
}
