export function handlePromoSuccess(result, promoTextField, resultTag, promoApplyBtn = null) {
  promoTextField.value = '';
  resultTag.classList.remove('text-error');
  resultTag.classList.add('text-success');
  resultTag.innerHTML = result;
  if (promoApplyBtn) promoApplyBtn.removeAttribute('disabled');
}

export function handlePromoError(result, resultTag, promoApplyBtn = null) {
  resultTag.classList.remove('text-success');
  resultTag.classList.add('text-error');
  resultTag.innerHTML = result;
  if (promoApplyBtn) promoApplyBtn.removeAttribute('disabled');
}
