import ApplicationController from "./application_controller";
import Sortable from "sortablejs";

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      handle: ".drag-handle",
      onEnd: this.updatePosition.bind(this),
    });
  }

  updatePosition(event) {
    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          "Sortable#insert_at",
          event.item.dataset.sgid,
          event.newIndex + 1
        );
      },
    });
  }
}
