import ApplicationController from './application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    document.addEventListener('stimulus-reflex-redirect', this.redirect);
  }

  disconnect() {
    super.disconnect();
    document.removeEventListener('stimulus-reflex-redirect', this.redirect);
  }

  redirect(event) {
    const { url } = event.detail;
    if (url) window.location.href = url;
  }
}
