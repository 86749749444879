import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["sidebar", "menu", "menuOverlay", "mobileMenu"];

  connect() {
    super.connect();
  }

  openSidebar() {
    this.mobileMenuTarget.classList.remove("invisible");
    this.menuOverlayTarget.classList.add("is-open");
    this.menuTarget.classList.add("is-open");
  }

  closeSidebar() {
    this.mobileMenuTarget.classList.add("invisible");
    this.menuOverlayTarget.classList.remove("is-open");
    this.menuTarget.classList.remove("is-open");
  }
}
