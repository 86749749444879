import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  static targets = [];

  connect() {
    super.connect();

    this.showReactivatedModal();
    this.showChangeSubscriptionModal();
  }

  showReactivatedModal() {
    const reactivated = document.getElementById('reactivated');
    if (reactivated?.value !== 'true') return;

    this.callStimulusAction({
      cb: () => {
        this.stimulate('Store::MembersPortal::ReactivatedReflex#set_reactivated_details');
      },
    });
  }

  showChangeSubscriptionModal() {
    const skipAMonth = document.getElementById('subscription_skip_a_month');
    if (skipAMonth?.value !== 'true') return;

    const { subscriptionId, variantId } = skipAMonth.dataset;
    this.callStimulusAction({
      cb: () => {
        this.stimulate('Store::MembersPortal::ChangeSubscription#set_change_subscription_details', +subscriptionId, +variantId)
          .then(() => document.getElementById('store_subscriptions_subscription_subscription_type_id_skip').click());
      },
    });
  }
}
