import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    document.addEventListener('stimulus-reflex:halted', this.reachOutToCustomerCareModal.bind(this));
  }

  disconnect() {
    super.disconnect();
    document.removeEventListener('stimulus-reflex:halted', this.reachOutToCustomerCareModal.bind(this));
  }

  reasonSelected(e) {
    e.target.setAttribute('data-reason-selected', true);
    const overrideBtn = document.getElementById('override-btn');

    if (!overrideBtn) {
      this.enableSubmitBtn();
    } else if (overrideBtn.dataset.override) {
      this.enableSubmitBtn();
    }
  }

  enableSubmitBtn() {
    const submitBtn = document.getElementById('submit-btn');
    submitBtn.classList.remove('cursor-not-allowed');
    submitBtn.disabled = false;
  }

  disableSubmitBtn() {
    const submitBtn = document.getElementById('submit-btn');
    submitBtn.classList.add('cursor-not-allowed');
    submitBtn.disabled = true;
  }

  toggleSubmitBtn(overrideBtn = null, e) {
    if (!overrideBtn) {
      e.target.value.length ? this.enableSubmitBtn() : this.disableSubmitBtn();
    } else if (overrideBtn.dataset.override) {
      this.enableSubmitBtn();
    }
  }

  setOverrideAttribute(e) {
    e.target.dataset.override = true;
    if ($('input[data-reason-selected="true"]').length) this.enableSubmitBtn();
  }

  reachOutToCustomerCareModal(event) {
    if (event.detail.reflex !== 'Store::MembersPortal::CancelAccountReflex#set_cancel_account_details') return;

    this.callStimulusAction({
      cb: () => {
        this.stimulate('Store::MembersPortal::CancelAccount#reach_out_to_cc');
      },
    });
  }
}
