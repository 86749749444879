import ApplicationController from './application_controller';

export default class extends ApplicationController {
  // Connect controller directly on element/icon to flash
  connect() {
    super.connect();

    this.interval = setInterval(() => this.flash(), 500);
  }

  disconnect() {
    super.disconnect();

    clearInterval(this.interval);
  }

  flash() {
    this.element.style.visibility = (this.element.style.visibility === 'hidden' ? 'visible' : 'hidden');
  }
}
