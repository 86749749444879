import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.timestamp = this.element.dataset.timestamp;
  }

  removeAnswerOption() {
    // Ensure last answer option form can't be removed
    if (document.querySelectorAll('[answer-option-form]').length === 1) {
      alert('Cannot remove last answer.');
      return;
    }

    // If record hasn't been saved, remove form from the page
    if (this.element.dataset.newRecord === 'true') {
      this.element.remove();
    } else {
      // Delete record from database, then remove form from page
      const ok = confirm("Are you sure you'd like to delete this answer?");
      if (!ok) return false;

      // https://docs.stimulusreflex.com/rtfm/lifecycle#promises
      this.stimulate('Admin::Questionnaires::QuestionAndAnswer#delete_answer_option', this.element.dataset.recordId)
        // Successful record deletion
        .then(payload => {
          const { data, element, event } = payload;
          const { attrs, reflexId } = data;
          document.getElementById(`ao-${this.timestamp}`).remove();
        })
        // Error upon record deletion
        .catch(payload => {
          const { data, element, event } = payload;
          const { attrs, reflexId } = data;
          const { error } = event.detail.stimulusReflex;
          // TODO: handle error on record deletion
        });
    }
  }

  // Hide/show answer option form
  toggleAnswerDropdown() {
    const answerField = this.element.querySelector(`[answer-field='${this.timestamp}']`);
    const dropdownIcon = this.element.querySelector(`[dropdown-icon-answer='${this.timestamp}']`);

    if (answerField.classList.contains('hidden')) {
      dropdownIcon.classList.remove('-rotate-90');
      answerField.classList.remove('hidden');
    } else {
      dropdownIcon.classList.add('-rotate-90');
      answerField.classList.add('hidden');
    }
  }

  // Hide/show settings area for answer option
  toggleSettingsDropdown() {
    const settingsFields = this.element.querySelector(`[settings-fields='${this.timestamp}']`);
    const dropdownIcon = this.element.querySelector(`[dropdown-icon-settings='${this.timestamp}']`);

    if (settingsFields.classList.contains('hidden')) {
      dropdownIcon.classList.add('rotate-90');
      settingsFields.classList.remove('hidden');
    } else {
      dropdownIcon.classList.remove('rotate-90');
      settingsFields.classList.add('hidden');
    }
  }

  // Hide/show more info area for answer option
  toggleMoreInfoDropdown() {
    const moreInfoFields = this.element.querySelector(`[more-info-fields='${this.timestamp}']`);

    if (moreInfoFields.classList.contains('hidden')) {
      moreInfoFields.classList.remove('hidden');
    } else {
      moreInfoFields.classList.add('hidden');
    }
  }
}
