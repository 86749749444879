import { Controller } from 'stimulus';
import 'jquery-ui-dist/jquery-ui.min.js';
import 'jquery-ui-dist/jquery-ui.min.css';

export default class extends Controller {
  static targets = ['address1', 'address1Error'];

  connect() {
    this.address1Targets.forEach((addressInput, index) => {
      this.autocompleteAddress(addressInput, this.address1ErrorTargets[index]);
    });
  }

  // https://api.jqueryui.com/autocomplete/
  autocompleteAddress(addressInput, addressError) {
    $(addressInput).autocomplete({
      minLength: 8,
      delay: 1000,
      source: (_request, response) => {
        $.ajax({
          url: '/api/smarty/suggestions',
          dataType: 'json',
          data: { search: addressInput.value, filterByShippingZone: addressInput.getAttribute('data-address-type') == 'shipping' },
          success: (data) => {
            if (addressError) addressError.innerHTML = '';
            if ((data.suggestions == null) || (data.suggestions.length <= 0)) {
              response([{ label: 'No Suggestions Found', val: -1 }]);
            } else {
              response($.map(data.suggestions, function(item) {
                return {
                  label: item.street_line + ' ' + item.secondary + ' ' + item.city + ', ' + item.state + ' ' + item.zipcode,
                  data: item,
                  value: item.street_line
                }
              }));
            }
          },
          error: (jqXHR, _textStatus, _errorThrown) => {
            if (jqXHR.status == 429) { // Too Many Requests
              const error = `Address autocompletion is unavailable at this time. Please try again in ${jqXHR.getResponseHeader('retry-after')} seconds.`;
              console.log(error);
            }
          }
        });
      },
      select: function(_e, u) {
        if (u.item.val == -1) {
          $(this).val('');
          return false;
        } else {
          var container = $(this).closest('.smarty-us-autocomplete-pro-container');
          $(container).find('.smarty-us-autocomplete-address2').val(u.item.data['secondary']);
          $(container).find('.smarty-us-autocomplete-city').val(u.item.data['city']);
          $(container).find('.smarty-us-autocomplete-zip').val(u.item.data['zipcode']);
          $(container).find('.smarty-us-autocomplete-state').find(`[data-code='${u.item.data['state']}']`).prop('selected', true);
          $(this).blur();
        }
      }
    });
    $('.ui-helper-hidden-accessible').hide();
  }
}
