import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  static targets = ['comment', 'submit'];

  connect() {
    super.connect();
    this.commentTarget.addEventListener(
      'input',
      this.onCommentInput.bind(this),
    );

    const radioButtons = this.element.querySelectorAll('input[type="radio"]');
    radioButtons.forEach((radio) => {
      radio.addEventListener('click', (event) => this.onRadioClick(event));
    });
  }

  onRadioClick(event) {
    const selectedRadio = event.target;

    if (selectedRadio) {
      const submitButton = this.submitTarget;
      submitButton.disabled = false;
      submitButton.classList.add('cursor-pointer');
      const { strength } = selectedRadio.dataset;
      const comment = this.commentTarget;
      comment.value = `I would like to change my Rugiet Ready ED treatment strength to ${strength}`;
    }
  }

  onCommentInput() {
    const comment = this.commentTarget;
    const submitButton = this.submitTarget;

    submitButton.disabled = comment.value.trim() === '';
  }
}
