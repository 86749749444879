import ApplicationController from '../../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.removeStoredFieldValues();
    $('#store-container').removeClass('px-[25px]');
    $('.checkout-progress').addClass('mx-auto mobile:mx-0 w-[316px] mobile:w-full');
    $('.checkout-progress__bar').addClass('px-[25px] mobile:px-0');
  }

  removeStoredFieldValues() {
    [
      'checkout_form_ship_address_attributes_address1',
      'checkout_form_ship_address_attributes_address2',
      'checkout_form_ship_address_attributes_city',
      'checkout_form_ship_address_attributes_zipcode',
    ].forEach((fieldId) => {
      if (localStorage.getItem(fieldId)) {
        localStorage.removeItem(fieldId);
      }
    });
  }
}
