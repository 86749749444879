import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  static targets = ['pause', 'option'];

  connect() {
    super.connect();
    this.element.addEventListener('change', this.onFormChange.bind(this));

    const pauseRadio = document.getElementById('pause');

    if (!pauseRadio) {
      this.onFormChange();
    }
  }

  onFormChange() {
    const pauseRadio = document.getElementById('pause');
    const pauseReasons = $('.pause-reasons');
    const titleAndSubtitle = $('.title-and-subtitle');
    const submitBtn = $('#submitBtn');

    const isPaused = pauseRadio && pauseRadio.checked;

    const reasonSelected =
      $(
        'input[name="store_subscriptions_subscription[cancellation_reasons]"]:checked',
      ).length > 0;

    const enableSubmit = !isPaused || reasonSelected;
    submitBtn.prop('disabled', !enableSubmit);
    submitBtn.addClass('cursor-pointer');

    if (isPaused) {
      pauseReasons
        .removeClass('invisible opacity-0 max-h-0')
        .addClass('opacity-100 max-h-full');
      titleAndSubtitle.addClass('hidden');
    } else {
      pauseReasons
        .addClass('invisible opacity-0 max-h-0')
        .removeClass('opacity-100 max-h-full');
      titleAndSubtitle.removeClass('hidden');
    }
  }

  submit(e) {
    const reason = $('.pause-reasons').find('input[type=radio]:checked');
    if (reason.val() !== 'Other: Please explain.') return;

    // Add text from "Other" option text field to the radio button input field
    e.preventDefault();
    $(e.currentTarget).submit();
  }
}
