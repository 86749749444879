/*
Used to force the CSRF tokens / Auth to be refreshed.
Simply connect the controller to the page and dispatch the event: `refreshCSRFToken`
*/

import Rails from '@rails/ujs';
import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
    document.addEventListener('refreshCSRFToken', this.refreshCSRFToken);
  }

  refreshCSRFToken() {
    // Ensure CSRF tokens are refreshed before updating forms.
    Rails.refreshCSRFTokens();

    // Iterate over all forms and update or insert the CSRF token.
    document.querySelectorAll('form').forEach((form) => {
      let csrf = form.querySelector('input[name="authenticity_token"]');

      // If the CSRF input exists, update its value.
      if (csrf) {
        csrf.value = Rails.csrfToken();
      } else {
        // If the CSRF input does not exist, create it and append to the form.
        csrf = document.createElement('input');
        csrf.type = 'hidden';
        csrf.name = 'authenticity_token';
        csrf.value = Rails.csrfToken();
        form.appendChild(csrf);
      }
    });
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener('refreshCSRFToken', Rails.refreshCSRFTokens);
  }
}
