import ApplicationController from '../../application_controller';
import Sortable from 'sortablejs';

export default class PharmacistsCompoundingLogController extends ApplicationController {
  connect() {
    super.connect();

    this.sortable = Sortable.create(this.element, {
      animation:  150,
      handle:     '.drag-handle',
      ghostClass: 'bg-primary',
    });
  }
}
